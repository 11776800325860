.App {
  text-align: center;
  background-color: #282c34;
  color: #e6e6e6;

  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 1em;
}

.Button {
  background-color: transparent;
  border: 1px solid white;
  border-radius: 5px;
  padding: 10px;
  font-size: 1.5em;
  font-weight: bold;
  color: white;
}

.Button:hover {
  background-color: grey;
}

@media (prefers-color-scheme: light) {
  .App {
    color: #282c34;
    background-color: #e6e6e6;
  }
  .Button {
    color: black;
    border-color: black;
  }
  .Button:hover {
    background-color: gray;
  }
}
